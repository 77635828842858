import {
  INIT_TOURS,
  SET_TOUR,
  RESET_TOUR,
  RESET_ALL_TOURS,
  SET_TOUR_DATA,
  RESET_TOUR_DATA,
} from '../action-types'

export const initTours = () => {
  return { type: INIT_TOURS }
}
export const setTour = payload => {
  return { type: SET_TOUR, payload }
}
export const resetTour = payload => {
  return { type: RESET_TOUR, payload }
}
export const resetAllTours = () => {
  return { type: RESET_ALL_TOURS }
}

export const setTourData = (current, steps) => {
  return { type: SET_TOUR_DATA, payload: { current, steps } }
}
export const resetTourData = () => {
  return { type: RESET_TOUR_DATA }
}
