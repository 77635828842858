import {
  GET_STORES,
  SCAN_BARCODE,
  GET_PRODUCT_DETAILS,
  RESET_PRODUCT,
  SAVE_POSITION,
  REMOVE_POSITION,
} from '../action-types'
import { handleServerError } from 'Common/utils'
import api from 'Common/api'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    const stores = res.data
    return dispatch({ type: GET_STORES, payload: stores })
  } catch (error) {
    console.log(error)
    return handleServerError(error)
  }
}

export const scanBarcode = barcode => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.scan.barcode(barcode, storeId)
    dispatch({ type: SCAN_BARCODE, payload: res.data })

    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const resetProduct = () => {
  return { type: RESET_PRODUCT }
}
export const getProductDetails = id => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.product.get(id, storeId)
    dispatch({ type: GET_PRODUCT_DETAILS, payload: res.data })
    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const savePosition = body => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.product.savePosition(body, storeId)
    dispatch({ type: SAVE_POSITION })
    return res.status
  } catch (error) {
    return handleServerError(error)
  }
}
export const removePosition = body => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.product.removePosition(body, storeId)
    dispatch({ type: REMOVE_POSITION })
    return res.status
  } catch (error) {
    return handleServerError(error)
  }
}
