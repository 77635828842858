import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { notification, Modal, Input } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  EnvironmentFilled,
  WarningFilled,
} from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  scanBarcode,
  getProductDetails,
  resetProduct,
  savePosition,
  removePosition,
} from 'Redux/actions/app-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import BarcodeScanner from 'Components/BarcodeScanner'
import Product from 'Components/Product'
import Footer from 'Components/Footer'
import {
  ActionButtonsContainer,
  Feedback,
  ProductContainer,
  ProductPosition,
} from './styled'
import { Content } from 'Common/styled'
import {
  BARCODE_TYPE_POSITION,
  BARCODE_TYPE_PRODUCT,
  BARCODE_TYPE_UNKNOWN,
} from 'Common/constants'

const initialState = {
  action: undefined,
  barcodeType: undefined,
  isLoading: false,
  isScanning: false,
  isScanDisabled: false,
  hasProductError: false,
  hasPositionError: false,
  hasPositionStoreError: false,
  hasProductBarcodeError: false,
  hasPositionBarcodeError: false,
}
class Home extends Component {
  static propTypes = {
    getProductDetails: PropTypes.func,
    product: PropTypes.object,
    removePosition: PropTypes.func,
    resetProduct: PropTypes.func,
    savePosition: PropTypes.func,
    scanBarcode: PropTypes.func,
  }
  state = {}

  componentDidMount() {
    this.setState(initialState)
  }

  componentDidUpdate(prevProps, prevState) {
    const { storeId, product } = this.props
    if (product && prevProps.storeId !== storeId) {
      this.resetData()
    }
  }

  resetData = () => {
    const { resetProduct } = this.props
    resetProduct()
    this.setState(initialState)
  }

  startScanning = () => this.setState({ isScanning: true })
  stopScanning = () => this.setState({ isScanning: false })

  handleBarcodeScan = async barcode => {
    const { scanBarcode, product } = this.props

    this.stopScanning()

    const res = await scanBarcode(barcode)
    const barcodeType = res.type

    this.setState({
      barcodeType,
      hasProductError: false,
      hasPositionError: false,
      hasProductBarcodeError: false,
      hasPositionBarcodeError: false,
    })

    switch (barcodeType) {
      case BARCODE_TYPE_UNKNOWN:
        return this.setState({ hasProductError: true })
      case BARCODE_TYPE_PRODUCT:
        if (product) {
          return this.setState({ hasProductBarcodeError: true })
        }
        return this.getProductDetails(res.product.id)
      case BARCODE_TYPE_POSITION:
        if (product) {
          return this.handlePosition(res.position)
        }
        return this.setState({ hasPositionBarcodeError: true })
      default:
        return
    }
  }

  getProductDetails = async ean => {
    const { getProductDetails } = this.props
    this.setState({ isLoading: true })

    const product = await getProductDetails(ean)
    const newState = { isLoading: false }
    if (!product) newState.hasProductError = true
    if (product?.position) newState.isScanDisabled = true

    this.setState(newState)
  }

  openManualInsertModal = () => {
    const { intl } = this.props
    Modal.confirm({
      title: intl.formatMessage({ id: 'home.manualInsertModal.title' }),
      content: (
        <Input
          size={'large'}
          id={'barcode'}
          placeholder={intl.formatMessage({
            id: 'home.manualInsertModal.placeholder',
          })}
        />
      ),
      okText: <CheckOutlined />,
      cancelText: <CloseOutlined />,
      okButtonProps: { size: 'large' },
      cancelButtonProps: { size: 'large' },
      onOk: () => {
        const barcode = document.getElementById('barcode').value
        this.handleBarcodeScan(barcode)
      },
    })
  }

  handlePosition = async position => {
    const { action } = this.state

    this.setState({ isLoading: true })
    switch (action) {
      case 'removePosition':
        return this.removePosition(position)
      case 'replacePosition':
        return this.replacePosition(position)
      default:
        return this.savePosition(position)
    }
  }

  savePosition = async position => {
    const { intl, product, savePosition } = this.props
    const body = { productIds: [product.id], position }
    const res = await savePosition(body)

    switch (res) {
      case 200:
        notification.success({
          message: intl.formatMessage(
            {
              id: 'home.message.savePositionSuccess',
            },
            {
              id: product.id,
              position: `${position.rowName}.${position.zoneName}`,
            }
          ),
        })
        this.resetData()
        break
      case 202:
        this.setState({ isLoading: false, hasPositionStoreError: true })
        break
      default:
        this.setState({ isLoading: false })
        break
    }
  }

  replacePosition = async position => {
    const { intl, product, removePosition, savePosition } = this.props
    const removeBody = { productIds: [product.id], position: product.position }
    const saveBody = { productIds: [product.id], position }

    await removePosition(removeBody)
    const res = await savePosition(saveBody)
    if (res === 200)
      notification.success({
        message: intl.formatMessage(
          { id: 'home.message.replacePositionSuccess' },
          {
            id: product.id,
            position: `${position.rowName}.${position.zoneName}`,
          }
        ),
      })
    this.resetData()
  }

  removePosition = async position => {
    const { intl, product, removePosition } = this.props
    const body = { productIds: [product.id], position }
    const res = await removePosition(body)

    if (res === 200)
      notification.warning({
        message: intl.formatMessage(
          { id: 'home.message.removePositionSuccess' },
          {
            id: product.id,
            position: `${position.rowName}.${position.zoneName}`,
          }
        ),
      })

    this.resetData()
  }

  setAction = action => this.setState({ action, isScanDisabled: false })
  cancelAction = () =>
    this.setState({ action: undefined, isScanDisabled: true })

  renderBarcodeImg(width) {
    const style = { display: 'block', margin: '0 auto', width }
    return <img style={style} src={'/assets/img/barcode.png'} alt={'barcode'} />
  }
  renderProduct() {
    const { isLoading } = this.state
    const { product } = this.props

    if (isLoading) {
      return (
        <ProductContainer>
          <Loader height={'150px'} />
        </ProductContainer>
      )
    }
    if (product) {
      return (
        <ProductContainer>
          <Product data={product} />
        </ProductContainer>
      )
    }
    return <ProductContainer>{this.renderBarcodeImg(200)}</ProductContainer>
  }
  renderActions() {
    const {
      isLoading,
      isScanning,
      barcodeType,
      action,
      hasProductError,
      hasPositionError,
      hasPositionStoreError,
      hasProductBarcodeError,
      hasPositionBarcodeError,
    } = this.state
    const { product } = this.props
    if (isLoading) {
      return <Loader height={'100px'} />
    }
    if (isScanning) {
      return <BarcodeScanner onScan={this.handleBarcodeScan} />
    }
    if (hasProductError) {
      return (
        <Feedback
          icon={<WarningFilled />}
          status={'error'}
          subTitle={<FormattedMessage id={'home.error.product'} />}
        />
      )
    }
    if (hasPositionError) {
      return (
        <Feedback
          icon={<WarningFilled />}
          status={'error'}
          subTitle={<FormattedMessage id={'home.error.position'} />}
        />
      )
    }
    if (hasPositionStoreError) {
      return (
        <Feedback
          icon={<WarningFilled />}
          status={'error'}
          subTitle={<FormattedMessage id={'home.error.positionStore'} />}
        />
      )
    }
    if (hasProductBarcodeError) {
      return (
        <Feedback
          status={'warning'}
          subTitle={<FormattedMessage id={'home.error.productBarcode'} />}
        />
      )
    }
    if (hasPositionBarcodeError) {
      return (
        <Feedback
          status={'warning'}
          subTitle={<FormattedMessage id={'home.error.positionBarcode'} />}
        />
      )
    }
    if (!product?.position && barcodeType === 'product') {
      return (
        <Feedback
          icon={<EnvironmentFilled />}
          subTitle={<FormattedMessage id={'home.text.scanPositionBarcode'} />}
        />
      )
    }
    if (product?.position) {
      switch (action) {
        case 'removePosition':
          return (
            <Feedback
              status={'warning'}
              icon={<EnvironmentFilled />}
              subTitle={<FormattedMessage id={'home.text.removePosition'} />}
              extra={[
                <Button
                  key={'cancel'}
                  type={'text'}
                  onClick={this.cancelAction}>
                  <FormattedMessage id={'home.button.cancel'} />
                </Button>,
              ]}
            />
          )
        case 'replacePosition':
          return (
            <Feedback
              icon={<EnvironmentFilled />}
              subTitle={<FormattedMessage id={'home.text.replacePosition'} />}
              extra={[
                <Button
                  key={'cancel'}
                  type={'text'}
                  onClick={this.cancelAction}>
                  <FormattedMessage id={'home.button.cancel'} />
                </Button>,
              ]}
            />
          )
        default:
          return (
            <ActionButtonsContainer>
              <FormattedMessage id={'home.text.productWithPosition'} />
              <ProductPosition>
                <EnvironmentFilled />{' '}
                {`${product.position.rowName}.${product.position.zoneName}`}
              </ProductPosition>
              <Button onClick={() => this.setAction('removePosition')}>
                <FormattedMessage id={'home.button.removePosition'} />
              </Button>
              <Button onClick={() => this.setAction('replacePosition')}>
                <FormattedMessage id={'home.button.replacePosition'} />
              </Button>
            </ActionButtonsContainer>
          )
      }
    }
    return (
      <Feedback
        icon={<div></div>}
        subTitle={<FormattedMessage id={'home.text.scanProductBarcode'} />}
      />
    )
  }
  render() {
    const { action, isScanDisabled } = this.state
    return (
      <>
        <Content action={action}>
          {this.renderProduct()}
          {this.renderActions()}
        </Content>
        <Footer
          isScanDisabled={isScanDisabled}
          onManualInsertClick={this.openManualInsertModal}
          onScanButtonClick={this.startScanning}
          onFinishButtonClick={this.resetData}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.storeId,
  product: state.app.product,
})
const mapDispatchToProps = {
  scanBarcode,
  getProductDetails,
  resetProduct,
  savePosition,
  removePosition,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
