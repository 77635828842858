import {
  GET_STORES,
  SCAN_BARCODE,
  GET_PRODUCT_DETAILS,
  RESET_PRODUCT,
} from '../action-types'

export const initialState = {
  stores: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORES:
      return { ...state, stores: payload }
    case SCAN_BARCODE:
      const { type, product, position } = payload
      return {
        ...state,
        type,
        position,
        product: product ? { ...state.product, ...product } : state.product,
      }
    case RESET_PRODUCT:
      return { ...initialState, stores: state.stores }
    case GET_PRODUCT_DETAILS:
      return { ...state, product: { ...state.product, ...payload } }
    default:
      return state
  }
}
