import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Link } from 'react-router-dom'
import { QuestionOutlined } from '@ant-design/icons'

import User from 'Components/User'
import { StyledHeader, HeaderBar, AppLogo, TutorialIcon } from './styled'

const Header = props => {
  const { hasTour, user, changeStore, resetTour } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader hasTour={hasTour}>
        <Link to={'/'}>
          <AppLogo src={'/assets/img/logo.png'} />
        </Link>
        {!isEmpty(user) ? (
          <User user={user} onClick={user.isAdmin ? changeStore : null} />
        ) : null}
        {hasTour && (
          <TutorialIcon
            icon={<QuestionOutlined />}
            size={'small'}
            onClick={resetTour}
          />
        )}
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  changeStore: PropTypes.func,
  hasTour: PropTypes.bool,
  user: PropTypes.object,
  resetTour: PropTypes.func,
}
export default Header
