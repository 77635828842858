// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'

export const GET_STORES = 'GET_STORES'

// TOUR
export const INIT_TOURS = 'INIT_TOURS'
export const SET_TOUR = 'SET_TOUR'
export const RESET_TOUR = 'RESET_TOUR'
export const RESET_ALL_TOURS = 'RESET_ALL_TOURS'
export const SET_TOUR_DATA = 'SET_TOUR_DATA'
export const RESET_TOUR_DATA = 'RESET_TOUR_DATA'

export const SCAN_BARCODE = 'SCAN_BARCODE'
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS'
export const RESET_PRODUCT = 'RESET_PRODUCT'
export const SAVE_POSITION = 'SAVE_POSITION'
export const REMOVE_POSITION = 'REMOVE_POSITION'
