import styled from 'styled-components'
import { colors } from 'Common/constants'
import { Result } from 'antd'

export const ProductContainer = styled.div`
  background-color: ${colors.white};
  min-height: 200px;
  padding: 24px;
`
export const ActionButtonsContainer = styled.div`
  text-align: center;
  padding: 16px;
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const ProductPosition = styled.span`
  font-size: 24px;
  color: ${colors.main};
`
export const Feedback = styled(Result)`
  & .ant-result-title {
    color: ${colors.grey4};
  }
  & .ant-result-subtitle {
    font-size: 18px;
    color: ${colors.grey4};
  }
`
