import styled from 'styled-components'
import { Layout as AntLayout, Button } from 'antd'

import { colors } from './constants'

export const Layout = styled(AntLayout)`
  /* background: ${colors.white}; */
`
export const Content = styled(AntLayout.Content)`
  /* padding: 16px; */
  min-height: ${props =>
    props.action === 'viewPosition'
      ? 'calc(100vh - 80px)'
      : 'calc(100vh - 155px)'};
  /* display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content)); */
  @media screen and (orientation: landscape) {
    min-height: ${props =>
      props.action === 'viewPosition'
        ? 'calc(100vw - 80px)'
        : 'calc(100vw - 155px)'};
  }
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
