import API from './axiosConfig'
import { BU } from './constants'

export default {
  user: {
    authenticate: token =>
      API.get('/user', {
        headers: { Authorization: `Bearer ${token}` },
      }),
  },
  store: {
    list: () => API.get('/stores', { params: { bu: BU } }),
  },
  scan: {
    barcode: (barcode, storeId) =>
      API.get(`/scan/${barcode}`, { params: { storeId, bu: BU } }),
  },
  product: {
    get: (id, storeId) =>
      API.get(`/product/${id}`, { params: { storeId, bu: BU } }),
    savePosition: (body, storeId) =>
      API.post('/product/position/save', body, { params: { storeId, bu: BU } }),
    removePosition: (body, storeId) =>
      API.post('/product/position/remove', body, {
        params: { storeId, bu: BU },
      }),
  },
}
