import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  BarcodeOutlined,
  FormOutlined,
  CloseCircleFilled,
} from '@ant-design/icons'
import { StyledFooter, FooterButton, ScanButton } from './styled'

const Footer = props => {
  const {
    isScanDisabled,
    onManualInsertClick,
    onScanButtonClick,
    onFinishButtonClick,
  } = props
  return (
    <StyledFooter>
      <FooterButton
        type={'text'}
        icon={<FormOutlined />}
        disabled={isScanDisabled}
        onClick={onManualInsertClick}>
        <FormattedMessage id={'footer.button.manualInsert'} />
      </FooterButton>
      <ScanButton
        shape={'circle'}
        icon={<BarcodeOutlined />}
        disabled={isScanDisabled}
        onClick={onScanButtonClick}
      />
      <FooterButton
        type={'text'}
        icon={<CloseCircleFilled />}
        onClick={onFinishButtonClick}>
        <FormattedMessage id={'footer.button.finish'} />
      </FooterButton>
    </StyledFooter>
  )
}
Footer.propTypes = {
  isScanDisabled: PropTypes.bool,
  onManualInsertClick: PropTypes.func,
  onScanButtonClick: PropTypes.func,
  onFinishButtonClick: PropTypes.func,
}
export default Footer
